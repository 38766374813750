import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/HeroSectionTeam";
import TeamSection from "../components/TeamMember/team";

class Theme extends Component {
    // constructor(props) {
    //     super(props);
    // }
  render() {
    return (
      <React.Fragment>
        <Header bgColor="white"/>
        <div className="main">
          <HeroSection />
          <TeamSection data={this.props.location.state} />
        </div>
      </React.Fragment>
    );
  }
}

export default Theme;
