import React from "react";
import _data from "../../data";

class TeamMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      kussens: {},
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      kussens: _data.kussens[this.props.data.matras][this.props.data.schouder][this.props.data.lighouding],
    });

    this.items = _data.kussens[this.props.data.matras][this.props.data.schouder][this.props.data.lighouding]
        .filter(item =>  this.props.data[item.materiaal] === true)
        .map((item) =>
        <div key={item.naam} className="col-lg-4 col-md-6 col-sm-6">
          <div className="single-team-member position-relative my-lg-3 my-md-3 my-sm-0">
            <div className="team-image">
              <img
                  src={item.image}
                  alt="Team Members"
                  className="img-fluid rounded"
              />
            </div>
            <div className="team-info text-white rounded d-flex flex-column align-items-center justify-content-center">
              <h5 className="mb-0">{ item.naam }</h5>
              <h6>{item.materiaal}</h6>
              <ul className="list-inline team-social social-icon my-4 text-white">
                <li className="list-inline-item">
                </li>
                <li className="list-inline-item">
                </li>
                <li className="list-inline-item">
                </li>
                <li className="list-inline-item">
                </li>
              </ul>
              <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn app-store-btn"
              >
                Details
              </a>
            </div>
          </div>
        </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <section className="team-member-section ptb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 col-md-8">
                <div className="section-heading text-center mb-4">
                  <h2>Dit is onze selectie</h2>
                  <p className="lead">
                    Deze kussens zijn geselecteerd op basis van de door u aangegeven informatie
                     <div className="font-italic d-inline-flex"> (matras: {this.props.data.matras}, schouder: {this.props.data.schouder === 'normaal' ? 'gemiddeld' : this.props.data.schouder}, lighouding: {this.props.data.lighouding})</div>.<br/>
                    Het dient als richtlijn om uw keuze te vergemakkelijken.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              {this.items}
            </div>
            <a
                className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                id="signIn"
                href="/#"
            >
              Opnieuw
            </a>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default TeamMember;
