import React from "react";
import { connect } from "react-redux";
import { signUp } from "../../actions/index";
import {Redirect} from "react-router-dom"

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {},
      matras: "",
      schouder: "",
      lighouding: "",
      materiaal: "",
      dons: false,
      mindfoam: false,
      natuurlatex: true,
      talalay: false,
      disableBtn: true,
      btnText: "Toon kussens",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changeBtnText = btnText => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    if (event.target.name === 'materiaal')
    {
      stateValue['talalay'] = false;
      stateValue['natuurlatex'] = false;
      stateValue['mindfoam'] = false;
      stateValue['dons'] = false;

      stateValue[event.target.value] = true;
    }
    this.setState(stateValue, () => this.checkEnableButton());
  }

  checkEnableButton() {
    if (this.state.matras !== '' && this.state.schouder !== '' && this.state.lighouding !== '' && this.state.materiaal !== '')
    {
      this.setState({disableBtn:false});
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    // disable the button
    this.setState({ disableBtn: true });

    // // get action
    const signUpAction = signUp(this.state);

    // // Dispatch the contact from data
    this.props.dispatch(signUpAction);

    // // added delay to change button text to previous
    setTimeout(
      function() {
        // enable the button
        this.setState({ disableBtn: false });

        // change to button name
        this.changeBtnText("Toon kussens");

        // get action again to update state
        const signUpAction = signUp(this.state);

        // Dispatch the contact from data
        this.props.dispatch(signUpAction);

        // clear form data
        this.setState({
          sent: true
        });
      }.bind(this),
      3000
    );
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    if (this.state.sent) {
      return (<Redirect to={{pathname: '/team', state: this.state}}/>);
    }
    return (
      <React.Fragment>
        <section
          className="hero-section ptb-100 background-img full-screen"
          // style={{
          //   backgroundImage: "url('img/hero-bg-1.jpg')",
          //   backgroundRepeat: "no-repeat",
          //   backgroundPosition: "center center",
          //   backgroundSize: "cover",
          // }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between pt-5 pt-sm-5 pt-md-5 pt-lg-0">
              <div className="col-md-5 col-lg-5">
                <div className="hero-content-left text-white">
                  <h1 className="text-white">Kussen Keuze Hulp</h1>
                  <p className="lead">
                    Met deze tool kunt u gemakkelijk het juiste kussen kiezen, aangepast aan uw situatie.
                  </p>
                </div>
              </div>
              <div className="col-md-7 col-lg-6">
                <div className="card login-signup-card shadow-lg mb-0">
                  <div className="card-body px-md-3 py-3">
                    <div className="mb-5">
                      <h6 className="h3">Uw situatie</h6>
                      <p className="text-muted mb-0">
                        Geef uw situtatie aan.
                      </p>
                    </div>
                    <form
                      className="login-signup-form"
                      method="post"
                      onSubmit={this.handleSubmit}
                    >
                      <div className="form-group">
                        <label className="pb-1 text-center col-12"><h5>Wat is de hardheid van uw matras?</h5></label>
                        <div className="input-group input-group-merge row text-center">

                          <div className="col">
                          <label>
                          <input
                              type="radio"
                              value="zacht"
                              name="matras"
                              checked={this.state.matras === "zacht"}
                              onChange={e =>
                                  this.handleFormValueChange("matras", e)
                              }/> Zacht
                          </label>
                          </div>
                          <div className="col">
                          <label>
                            <input
                                type="radio"
                                value="medium"
                                name="matras"
                                checked={this.state.matras === "medium"}
                                onChange={e =>
                                    this.handleFormValueChange("matras", e)
                                }/> Medium
                          </label>
                          </div>
                          <div className="col">
                          <label>
                            <input
                                type="radio"
                                value="hard"
                                name="matras"
                                checked={this.state.matras === "hard"}
                                onChange={e =>
                                    this.handleFormValueChange("matras", e)
                                }/> Hard
                          </label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="pb-1 text-center col-12"><h5>Wat is uw schouderbreedte?</h5></label>
                        <div className="input-group input-group-merge row text-center">

                          <div className="col">
                          <label>
                            <input
                                type="radio"
                                value="smal"
                                name="schouder"
                                checked={this.state.schouder === "smal"}
                                onChange={e =>
                                    this.handleFormValueChange("schouder", e)
                                }/> Smal
                          </label>
                          </div>
                          <div className="col">
                          <label>
                            <input
                                type="radio"
                                value="normaal"
                                name="schouder"
                                checked={this.state.schouder === "normaal"}
                                onChange={e =>
                                    this.handleFormValueChange("schouder", e)
                                }/> Gemiddeld
                          </label>
                          </div>
                          <div className="col">
                          <label>
                            <input
                                type="radio"
                                value="breed"
                                name="schouder"
                                checked={this.state.schouder === "breed"}
                                onChange={e =>
                                    this.handleFormValueChange("schouder", e)
                                }/> Breed
                          </label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="pb-1 text-center col-12"><h5>Wat is uw voorkeurs-slaaphouding?</h5></label>
                        <div className="input-group input-group-merge row text-center">

                          <div className="col">
                          <label>
                            <input
                                type="radio"
                                value="rug"
                                name="lighouding"
                                checked={this.state.lighouding === "rug"}
                                onChange={e =>
                                    this.handleFormValueChange("lighouding", e)
                                }/> Rug
                          </label>
                          </div>
                          <div className="col">
                          <label>
                            <input
                                type="radio"
                                value="zij"
                                name="lighouding"
                                checked={this.state.lighouding === "zij"}
                                onChange={e =>
                                    this.handleFormValueChange("lighouding", e)
                                }/> Zij
                          </label>
                          </div>
                          <div className="col">
                          <label>
                            <input
                                type="radio"
                                value="buik"
                                name="lighouding"
                                checked={this.state.lighouding === "buik"}
                                onChange={e =>
                                    this.handleFormValueChange("lighouding", e)
                                }/> Buik
                          </label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="pb-1 text-center col-12"><h5>Welk materiaal heeft uw voorkeur?</h5></label>
                        <div className="input-group input-group-merge row text-center">

                          <div className="col">
                            <label>
                              <input
                                  type="radio"
                                  name="materiaal"
                                  value="talalay"
                                  checked={this.state.materiaal === "talalay"}
                                  onChange={e =>
                                      this.handleFormValueChange('materiaal', e)
                                  }/> Talalay
                            </label>
                          </div>

                          <div className="col">
                            <label>
                              <input
                                  type="radio"
                                  name="materiaal"
                                  value="natuurlatex"
                                  checked={this.state.materiaal === "natuurlatex"}
                                  onChange={e =>
                                      this.handleFormValueChange('materiaal', e)
                                  }/> Natuurlatex
                            </label>
                          </div>


                          <div className="col">
                            <label>
                              <input
                                  type="radio"
                                  name="materiaal"
                                  value="mindfoam"
                                  checked={this.state.materiaal === "mindfoam"}
                                  onChange={e =>
                                      this.handleFormValueChange('materiaal', e)
                                  }/> Mindfoam
                            </label>
                          </div>


                          <div className="col">
                            <label>
                              <input
                                  type="radio"
                                  name="materiaal"
                                  value="dons"
                                  checked={this.state.materiaal === "dons"}
                                  onChange={e =>
                                      this.handleFormValueChange('materiaal', e)
                                  }/> Dons
                            </label>
                          </div>

                        </div>
                      </div>

                      <button
                        className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                        id="signIn"
                        disabled={this.state.disableBtn}
                        onClick={() => {
                          this.changeBtnText("Kussens zoeken...");
                        }}
                      >
                        {this.state.btnText}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-img-absolute">
            <img src="img/wave-shap.svg" alt="wave shape" className="img-fluid" />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state,
}))(HeroSection);
